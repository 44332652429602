.owl-theme {
	.owl-controls {
		position: absolute;
		top: 50%;
		width: 100%;
		transform: translateY(-50%); } }

.owl-theme .owl-controls .owl-buttons div {
	position: absolute;
	width: 28px;
	height: 45px;
	background: none; }


.owl-theme .owl-controls .owl-buttons .owl-prev {
	left: 0;
	background-image: url(../img/main_page/slider-arrows-iconset.png);
	background-repeat: no-repeat;
	background-position: 0 center; }

.owl-theme .owl-controls .owl-buttons .owl-next {
	right: 0;
	background-image: url(../img/main_page/slider-arrows-iconset.png);
	background-repeat: no-repeat;
	background-position: -39px center; }
