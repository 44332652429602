// MAIN INFO SECTION (1)

.s-main-info {
	min-height: 580px;
	background-color: #151518;
	background-image: url(../img/main_page/main_section-bg.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: #fff;
	padding-top: 10px;
	padding-bottom: 0;
	h1 {
		text-transform: uppercase;
		font-size: 40px;
		line-height: 1.4;
		margin-top: 46px; } }


.img-wrp_s-main-info {
	text-align: right;
	img {
		width: auto;
		height: 100%;
		position: relative; } }

.info_photo-wrp {
	position: absolute;
	left: -80px;
	bottom: 40px;
	display: inline-block; }

.txt-wrp_main-info {
	text-align: right;
	color: $font-color-w;
	h2 {
		font-family: "OpenSans-LightItalic";
		font-size: 29px;
		margin-bottom: 0;
		position: relative;
		display: inline-block;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background-color: $accent-color;
			bottom: 0;
			left: 0; } }

	p {
		font-family: "OpenSans-LightItalic";
		font-size: 15px;
		&:nth-child(2) {
			margin-top: 0;
			margin-bottom: 0;
			color: $accent-color; }
		&:last-child {
			margin-top: 42px;
			margin-bottom: 0; } } }

// contact form
.contact-form {
	background-color: #fff;
	padding: 40px 40px;
	max-width: 380px;
	color: $font-color-b;
	h2 {
		font-family: "OpenSans-Light";
		margin: 0 0 10px;
		text-align: center;
		font-size: 20px;
		strong {
			font-family: "OpenSans-SemiboldItalic";
			text-transform: uppercase; } } }

.submit-form {
	input {
		font-family: "OpenSans-LightItalic";
		font-size: 16px;
		border: none;
		border-bottom: 1px $font-color-b solid;
		padding: 10px 0 0 40px;
		width: 100%;
		position: relative;
		background-image: url(../img/main_page/c-f-iconset.png);
		background-repeat: no-repeat;
		background-position: 5px 4px;
		margin: 12px 0;
		&:nth-child(2) {
			background-position: 5px -40px; } } }


.submit-button {
	background-color: $accent-color;
	text-align: center;
	font-family: "OpenSans-Bold";
	color: $font-color-w;
	text-transform: uppercase;
	font-size: 18px;
	border: none;
	padding: 12px 40px;
	width: 100%;
	margin-top: 22px; }

.confidential_contact-form {
	text-align: center;
	color: $font-color-w;
	font-family: "Roboto-Light";
	font-size: 13px;
	max-width: 380px; }

// SERVICES SECTION
.s-services {
	color: $font-color-w; }

.section_heading-wrp {
	font-size: 34px;
	font-family: "OpenSans-Light";
	text-align: center;
	text-transform: uppercase;
	position: relative;
	color: $font-color-w;
	&::before {
		content: '';
		position: absolute;
		width: 175px;
		height: 10px;
		margin: 0 auto;
		left: 0;
		right: 0;
		bottom: -34px;
		height: 1px;
		background-color: $accent-color; }
	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		width: 10px;
		height: 10px;
		background-image: url(../img/header/hdr-elipse.png);
		bottom: -39px;
		margin: 0 auto;
		left: 0;
		right: 0;
		background-repeat: no-repeat; } }

.icon-wrp--item_s-services {
	margin-bottom: 26px;
	img {
		position: relative;
		transform: translateY(-50%);
		top: 52%; } }

.item_s-services {
	text-align: center;
	width: 19.5%;
	display: inline-block;
	vertical-align: top; }

.item-title_s-services {
	font-family: "OpenSans-LightItalic";
	font-size: 20px;
	color: $font-color-w;
	line-height: 1.48;
	display: block;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		width: 42px;
		height: 2px;
		left: 0;
		right: 0;
		top: -11px;
		margin: 0 auto;
		background-color: #fff; }
	&:hover {
		color: #fff;
		text-decoration: none; }
	&:hover	.hover-line-spec-color {
		text-decoration: none;
		border-bottom: 1px solid $accent-color; } }

.item-title_s-services--1 {
	width: 70%;
	margin: auto; }

.item-title_s-services--4 {
	width: 50%;
	margin: auto; }

.item-title_s-services--5 {
	white-space: nowrap; }

.item_s-services--container {
	margin-top: 80px; }


// ABOUT SECTION

.s-about {
	color: $font-color-b;
	background-color: #fff; }

.section_heading-wrp--white {
	color: $font-color-b;
	&::after {
		background-image: url(../img/header/hdr-elipse-white.png); } }

.info-container_about, .info-container_about p {
	font-size: 18px;
	font-family: "OpenSans-LightItalic";
	text-align: center;
	margin-top: 65px; }

.info-item_about {
	padding-left: 40px;
	position: relative;
	h3 {
		font-family: "OpenSans-SemiboldItalic";
		font-size: 20px; } }

.side-line_about-item {
	position: absolute;
	left: 0;
	top: 0; }

.info-item_about--list {
	ul {
		list-style-type: none;
		padding-left: 0; } }


.more-link {
	a {
		display: inline-block;
		margin-top: 16px;
		font-family: "OpenSans-LightItalic";
		font-size: 16px;
		border-bottom: 1px solid $accent-color;
		&:hover {
			border-bottom-color: transparent;
			text-decoration: none;
			color: $font-color-b; } } }

.item_s-services--about-s {
	width: auto;
	text-align: left;
	margin-top: 50px; }

.item-title_s-services--about {
	color: $font-color-b;
	&:hover {
		text-decoration: none;
		color: $font-color-b; }
	&:before {
		background-color: $accent-color;
		left: 0;
		margin: 0;
		width: 55px; }
	h2 {
		font-size: 24px;
		font-family: "OpenSans-SemiboldItalic";
		margin-bottom: 0; }
	p {
		margin-top: 10px; } }

// RECOMMENDATIONS SECTION
.s-recommendations {
	padding-top: 35px; }

.slider-wrp {
	position: relative;
	color: $font-color-w; }

.slide {
	margin-top: 64px;
	width: 100%; }

.content-wrp_slide {}


.text-wrp_slide {
	display: inline-block;
	padding: 0 100px 0 50px;
	width: 80%; }


.img-wrp_slide {
	display: inline-block;
	border: 3px solid $accent-color;
	width: 125px;
	height: 125px;
	margin-left: 100px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center 0; }



.title_slide {
	color: $accent-color;
	h3, p {
		margin: 0; }
	h3 {
		font-family: "OpenSans-Semibold";
		font-size: 20px; } }

.content_slide {
	p {
		font-family: "OpenSans-LightItalic";
		margin-bottom: 0;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			width: 22px;
			height: 18px;
			background-image: url(../img/main_page/quotes-iconset.png);
			background-position: 0 0;
			background-repeat: no-repeat;
			top: 0;
			left: -24px; }
		&::after {
			content: '';
			position: absolute;
			width: 22px;
			height: 18px;
			background-image: url(../img/main_page/quotes-iconset.png);
			background-position: -39px 0;
			background-repeat: no-repeat;
			bottom: 0;
			right: -10px; } } }


// MAP SECTION MAIN PAGE

.map-s {
	padding-bottom: 30px;
	position: relative; }
#map-main-pg {
	height: 398px;
	background-image: url(../img/main_page/map-sample.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	position: relative; }
.address-wrp--map {
	background-color: #fff;
	z-index: 60;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 298px;
	width: 526px;
	padding-left: 30px;
	&::after {
		content: '';
		position: absolute;
		height: 300px;
		width: 34px;
		top: 0;
		right: -34px;
		background-image: url(../img/main_page/traingle-map-info.png);
		background-size: 100% 105%;
		background-position: 0 -4px; }
	h1 {}
	ul {
		padding-left: 0;
		list-style-type: none;
		li {
			padding-left: 46px;
			position: relative;
			margin: 18px 0;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 64px;
				height: 35px;
				background-image: url(../img/main_page/map-contacts-iconset.png);
				background-repeat: no-repeat;
				background-position: 0 0; }
			&:nth-child(2) {
				&:before {
					background-position: 0 -36px; } }
			&:last-child {
				&:before {
					background-position: 0 -72px; } }
			a {
				border-bottom: 1px solid $accent-color;
				&:hover, &:focus, &:active {
					color: $font-color-b;
					text-decoration: none;
					border-bottom: none; } } } } }

.address-container {
	position: absolute;
	max-width:  1170px;
	left: 0;
	right: 0;
	top: 0;
	margin: 0 auto;
	height: 100%; }


.map-wrp {
	ymaps {
		z-index: 50; } }

// SUBMIT SECTION
.s-submit {
	padding-bottom: 120px; }

.section_heading-wrp_submit-s {
	h1 {
		max-width: 860px;
		margin: 0 auto 20px;
		line-height: 1.4; }
	h2 {
		font-family: "OpenSans-LightItalic";
		font-size: 21px;
		max-width: 700px;
		margin: 30px auto;
		text-transform: none; }
	&::before {
		display: none; }
	&::after {
		display: none; } }

.form-wrp_submit-s {
	text-align: center; }

.submit-form_submit-s {
	display: inline-block;
	position: relative;
	input, button {
		display: inline-block;
		width: 302px; }
	input {
		height: 53px;
		margin: 0 10px;
		padding-top: 0;
		background-position: 6px 10px;
		padding-left: 45px;
		&:nth-child(2) {
			background-position: 6px -31px; } }
	button {} }

.confidential_contact-form_submit-s {
	text-align: left;
	width: 290px;
	position: absolute;
	right: 0;
	bottom: -55px;
	padding-left: 38px;
	&::before {
		content: '';
		width: 38px;
		height: 38px;
		background-image: url(../img/main_page/confidential-icon.png);
		left: -10px;
		top: 5px;
		position: absolute;
		background-repeat: no-repeat; } }

.seo-s {
	background-image: none;
	background-color: #fff; }

// SOC SUBSECTION

.heading_soc-wrp {
	margin-top: 45px;

	border-top: 1px solid rgba($font-color-b, 0.45);
	h3 {
		font-family: "OpenSans-LightItalic";
		font-size: 20px;
		text-align: center; } }

.share-item_soc-wrp {
	margin-top: 20px; }

.soc-wrp {
	text-align: center; }

.soc-item {
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		background-image: url(../img/main_page/soc-iconset.png);
		background-repeat: no-repeat;
		background-position: 3px 0;
		margin: 0 8px;
		&:nth-child(2) {
			background-position: -67px 0; }
		&:nth-child(3) {
			background-position: -140px 0; }
		&:last-child {
			background-position: -210px 0; } } }


