
.main-hdr {
	color: #fff;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: $accent-color;
		left: 0;
		bottom: 46px; } }

.logo-wrp_main-hdr {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 15px; }

.contacts_main-hdr {
	margin-top: 20px;
	font-family: "OpenSans-Light";
	text-align: right;
	a {
		color: $font-color-w;
		position: relative; }

	p {
		font-size: 14px;
		display: inline-block;
		margin-right: 23px;
		padding-left: 32px;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			width: 81%;
			height: 1px;
			background-color: $accent-color;
			bottom: -2px;
			right: 0;
			display: none; }
		&::before {
			content: '';
			background-image: url(../img/header/hdr-iconset.png);
			background-repeat: no-repeat;
			height: 30px;
			width: 24px;
			position: absolute;
			top: -2px;
			left: 0; } } }

.contacts_main-hdr--email {
	p {
		padding-left: 36px;
		margin-right: 34px;
		&::before {
			background-position: -38px 0;
			width: 30px; } }
	p:hover::after {
		display: block; } }

.contacts_main-hdr--phone-sect {
	h3 {
		text-align: center;
		margin-bottom: 0;
		position: relative;
		line-height: 1.35;
		&::before {
				content: '';
				background-image: url(../img/header/hdr-iconset.png);
				background-repeat: no-repeat;
				height: 30px;
				width: 24px;
				position: absolute;
				top: 0;
				left: -12px;
				background-position: -82px 0; } }
	p {
		padding: 0;
		margin: 0;
		margin-bottom: 18px;
		text-align: center;
		display: block;
		&::after, &::before {
			display: none; } } }


// TOP NAV MENU
.top-menu.affix {
	background-image: url(../img/main_bg.png);
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	margin: 0;
	padding: 20px 0 10px 0;
	ul {
		margin-left: 40px;
		li {
			margin-top: 0;
			a:hover::before {
				display: none; } } } }

.top-menu {
	margin-top: 10px;
	ul, li {
		list-style-type: none;
		padding: 0; }
	ul {
		margin: 0; }
	li {
		padding: 0 0 14px 0;
		margin: 0 30px;
		display: inline-block;
		a {
			text-transform: uppercase;
			color: $font-color-w;
			font-size: 12px;
			letter-spacing: 0.044em;
			&:hover {
				text-decoration: none;
				color: $accent-color; } } }
	ul {
		li {
			position: relative;
			&:first-child {
				margin-left: 0; }
			&:last-child {
				margin-right: 0; }
			a {
				&::before {
					content: '';
					position: absolute;
					z-index: 1;
					width: 10px;
					height: 10px;
					background-image: url(../img/header/hdr-elipse.png);
					top: -12px;
					margin: 0 auto;
					left: 0;
					right: 0;
					background-repeat: no-repeat;
					display: none; } }
			a:hover::before {
				display: block; } } } }

// MOBILE MENU
// MOBILE MENU

.mobile-mnu {
	padding-bottom: 30px;
	top: 0;
	left: 0;
	width: 100%;
	background-image: url(../img/main_bg.png);
	z-index: 100; }

#toggle-menu {
	width: 40px;
	height: 42px;
	border: 1px solid transparent;
	border-radius: 4px;
	padding: 4px;
	z-index: 600;
	margin-left: 20px;
	padding: 2px;
	background-color: $accent-color; }

.mobile-mnu.affix {
	padding-bottom: 10px;
	padding-top: 10px {
		#toggle-menu {
			margin-top: 15px; } } }
.toggle-lines-wrp {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 15;

	span {
		display: block;
		position: absolute;
		width: 95%;
		height: 6px;
		background-color: #fff;
		border-radius: 5px;
		left: 0;
		right: 0;
		margin: 0 auto;
		&:first-child {
			top: 3px; }

		&:nth-child(2) {
			top: 50%;
			left: 0;
			top: 15px; }

		&:nth-child(3) {
			bottom: 3px;
			left: 0; } } }

// Mobile toggle menu body
.affix-mnu-container {
	position: fixed; }

.main-hdr {
	.submit-button-menu {
		position: fixed;
		margin-top: 9px;
		padding: 10px;
		top: 0;
		right: 20px;
		z-index: 400;
		width: 160px;
		font-size: 14px;
		display: none; } }

.main-hdr {
	.affix {
		.submit-button-menu {
			display: block; } } }

.main-hdr {
	.top-menu.affix {
		.submit-button-menu {
			margin-top: 12px; } } }

.toggle-menu-fix {
	position: fixed;
	top: 10px;
	margin-left: 10px!important; }
.mobile-toggle-menu-body {
	position: fixed;
	top: 0;
	z-index: 500;
	min-width: 100%;
	transition: all 0.3s ease-in-out;
	display: block;
	ul {
		list-style-type: none;
		padding: 0;
		margin-top: 0;

		li {
			text-align: center;
			padding: 15px 0;
			// background: linear-gradient(to top, rgba(0, 0, 0, 0.9),  rgba(8, 8, 8, 0.9))
			background-color: $font-color-b;
			background-image: url(../img/main_bg.png);
			&:first-child {
				padding-top: 35px; }
			&:last-child {
				padding-bottom: 35px; }
			a {
				font-family: "OpenSans-Light";
				font-size: 16px;
				text-transform: uppercase;
				color: #fff;
				&:hover, &:active, &:focus {
					text-decoration: none;
					color: $accent-color; } } } } }

.mobile-mnu.affix {
	.mobile-toggle-menu-body {
		display: block; } }


// dropdown menu
.sub-menu {
	background-image: url(../img/main_bg.png);
	position: absolute;
	margin: 0;
	top: 39px;
	left: -30px;
	z-index: -1000;
	min-width: 400px;
	display: none;
	// transition: all 0.5s ease-in-out

	li {
		border-top: 1px solid $accent-color;
		display: block!important;
		padding-top: 10px!important;
		padding-bottom: 10px!important;
		a:hover::before, a:hover::after {
			display: none!important; }
		&:first-child {
			margin-left: 30px!important; }

		&:last-child {
			margin-right: 30px!important;
			padding-bottom: 20px!important; } } }

.affix {
	.sub-menu {
		left: -70px; } }

.menu-item-has-children {
	&:hover {
		.sub-menu, .sub-menu li {
			display: block;
			z-index: 1000; } } }


