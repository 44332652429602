.contacts-s {
	background-color: #fff; }

.item-contacts {
	text-align: center;
	h3 {
		margin-top: 34px; }
	h2 {
		font-size: 20px; } }

.img-wrp_contacts {
	overflow: hidden;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 60px;
	img {
		max-width: 60px;
		height: auto; } }
