@import "bourbon/bourbon";
@import "partials/fonts";
@import "partials/vars";

// html.js .loader
// 	background: none repeat scroll 0 0 #ffffff
// 	bottom: 0
// 	height: 100%
// 	left: 0
// 	position: fixed
// 	right: 0
// 	top: 0
// 	width: 100%
// 	z-index: 9999
// 	.loader_inner
// 		background-image: url("../img/preloader.gif")
// 		background-size: cover
// 		background-repeat: no-repeat
// 		background-position: center center
// 		background-color: #fff
// 		height: 60px
// 		width: 60px
// 		margin-top: -30px
// 		margin-left: -30px
// 		left: 50%
// 		top: 50%
// 		position: absolute

body {
	font-family: "OpenSans-Light";
	font-weight: normal;
	font-size: 16px;
	min-width: 320px;
	position: relative;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	background-color: #262833;
	background-image: url(../img/main_bg.png);
	color: $font-color-b; }

// body input:focus:required:invalid,
// body textarea:focus:required:invalid
// 	color: red
// body input:required:valid,
// body textarea:required:valid
// 	color: green

.hidden {
	display: none; }

// Typography
p {
	font-size: 16px;
	font-family: "OpenSans-Light", $helvetica; }

h1 {
	font-size: 34px;
	font-family: "OpenSans-Light", $helvetica;
	font-weight: normal; }

h2 {
	font-size: 30px;
	font-family: "OpenSans-Semibold", $helvetica;
	font-weight: normal; }

h3 {
	font-size: 18px;
	font-family: "OpenSans-Semibold", $helvetica;
	font-weight: normal; }

a {
	font-family: "OpenSans-Light", $helvetica;
	text-decoration: none;
	color: $font-color-b;
	&:hover, &:active, &:focus {
		color: $accent-color; } }

strong, b {
	font-weight: normal;
	font-family: "OpenSans-Semibold"; }

section {
	padding: 50px 0; }

// Jquery actions classes
.hide-toggle-menu {
	transform: translate(-1200px, 0); }

#toggle-menu {
	.toggle-lines-wrp {
			.turn45-r {
				transform: rotate(45deg);
				top: 15px;
				transition: all 0.2s ease-in; } } }

#toggle-menu {
	.toggle-lines-wrp {
		.turn45-l {
			transform: rotate(-45deg);
			bottom: 15px;
			transition: all 0.2s ease-in; } } }

.hide-elem {
	visibility: hidden;
	opacity: 0; }

.show {
	visibility: visible;
	opacity: 1; }

.menu-hide {
	// position: absolute
	// top: 0
	transform: translate(0,-60px); }


@import "partials/_header";
@import "partials/_main-page";
@import "partials/_owl-carousel-customize";
@import "partials/_footer";
@import "partials/_about-pg";
@import "partials/_services-inner";
@import "partials/_recommendations-pg";
@import "partials/_blog";
@import "partials/_contacts-pg";
@import "partials/_pop-up";
@import "partials/_addons";



// ALWAYS END

@import "partials/media";
