// MAIN SECTION (1)

.main-s_about {
	background-color: #fff; }

.section_heading-wrp--white_about-pg {
	h1 {
		font-size: 21px;
		font-family: "OpenSans-SemiboldItalic";
		text-transform: none; }
	&::after {
		bottom: -65px; }
	&::before {
		bottom: -60px; } }

.item-main-s_about-pg {
	padding-right: 10px;
	h3 {
		font-family: "OpenSans-SemiboldItalic";
		font-size: 20px;
		color: $accent-color;
		display: block;
		position: relative;
		margin-top: 40px;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -9px;
			height: 3px;
			background-color: $accent-color;
			width: 97%; }
		&:first-child {
			margin-top: 100px; } } }

.item-main-s_about-pg--list {
	h3 {
		color: $font-color-b;
		&::before {
			display: none; } }
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			padding-left: 32px;
			position: relative;
			margin: 10px 0;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 27px;
				height: 21px;
				background-image: url(../img/about_page/list-style-item.png);
				background-repeat: no-repeat;
				background-position: 0 9px; }
			&:first-child {
				margin-top: 30px; } } } }

.item_team {
	color: $font-color-b;
	background-color: #fff;
	text-align: center;
	position: relative;
	padding: 110px 24px 50px;
	margin-top: 200px;
	ul {
		text-align: left;
		padding: 0;
		list-style-type: none;
		li {
			padding-left: 20px;
			position: relative;
			margin: 12px 0;
			&::before {
				content: '';
				position: absolute;
				top: 10px;
				left: 0;
				background-image: url(../img/about_page/li-elipse.png);
				background-repeat: no-repeat;
				width: 3px;
				height: 3px; } } } }


.img-wrp_team {
	margin: 0!important;
	height: 202px;
	width: 166px;
	position: absolute;
	top: -110px;
	right: 0;
	left: 0;
	margin: 0 auto!important; }

.name_team, .job-posit_team {
	color: $accent-color; }

.name_team {
	position: relative;
	h3 {
		font-family: "OpenSans-Semibold";
		font-size: 20px;
		white-space: nowrap; }
	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: -9px;
		height: 2px;
		background-color: $accent-color;
		width: 97%; } }

.job-posit_team {
	h4 {
		font-family: "OpenSans-Light";
		font-size: 16px;
		font-weight: normal; } }

.s-advantages {
	background-color: #fff; }

.item_s-services--about-s--adv {
	margin-top: 85px;
	width: auto;
	display: block; }

.icon-wrp--item_s-services--adv {
	img {
		transform: none;
		position: {}
		top: auto; } }

.item-title_s-services--about--adv {
	h2 {
		width: auto;
		font-size: 20px; } }

.adv-s_about-pg--list {
	margin-top: 35px;
	padding-left: 60px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		background-image: url(../img/about_page/list-style-item-big.png);
		background-repeat: no-repeat;
		top: 8px;
		left: 0;
		width: 62px;
		height: 28px; }
	h3 {
		margin-top: 0!important; }
	p {
		margin-bottom: 0; } }

.section-wa-heading-fix {
	h1, h2 {
		max-width: none; } }
