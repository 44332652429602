.article-info_blog {
	div {
		margin-top: 0; } }


.section_heading-wrp--inner-serv h2,
.section_heading-wrp--inner-serv h3 {
	font-family: "OpenSans-SemiboldItalic";
	font-size: 21px;
	text-transform: none;
	margin-top: 30px; }


.contacts_main-hdr--address {
	margin-top: 0; }

