.main-s_services {
	background-color: #fff; }

.section_heading-wrp--inner-serv {
	&::after, &::before {
		display: none; }
	h1 {
		font-family: "OpenSans-Light";
		font-size: 40px;
		position: relative;
		color: $font-color-b;
		text-transform: uppercase;
		line-height: 1.4;
		margin-bottom: 70px;
		&::before {
			content: '';
			position: absolute;
			width: 175px;
			height: 10px;
			margin: 0 auto;
			left: 0;
			right: 0;
			bottom: -34px;
			height: 1px;
			background-color: $accent-color; }
		&::after {
			content: '';
			position: absolute;
			z-index: 1;
			width: 10px;
			height: 10px;
			background-image: url(../img/header/hdr-elipse-white.png);
			bottom: -39px;
			margin: 0 auto;
			left: 0;
			right: 0;
			background-repeat: no-repeat; } }
	p {
		text-transform: none;
		font-size: 18px; }
	h2 {
		font-family: "OpenSans-SemiboldItalic";
		font-size: 21px;
		text-transform: none;
		margin-top: 30px; }
	h3 {
		font-size: 18px; }
	h4 {
		font-size: 16px; } }

.s-serv-details {
	color: $font-color-w; }

.item_serv-detailed {
	margin-top: 50px; }

.section_heading-wrp--add-mb {
	margin-bottom: 15px; }

.title_serv-detailed {
	position: relative;
	font-family: "PT_Serif_Caption";
	font-size: 55px;
	&::before {
		content: '';
		position: absolute;
		width: 54px;
		background-color: $accent-color;
		height: 3px;
		bottom: 0;
		left: 0; } }

.text-wrp_serv-detailed {
	p {
		font-family: "OpenSans-LightItalic";
		font-size: 16px; } }

// SERVICES PAGE
.s-services-inverted-color {
	background-color: #fff;
	color: $font-color-b;
	.item-title_s-services {
		color: $font-color-b;
		&::before {
			background-color: $accent-color; } } }
