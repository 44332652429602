.main-footer {
	color: $font-color-w;
	padding: 30px 0 35px 0;
	p {
		font-size: 13px; } }

.copyright {
	padding-top: 8px; }

.madeby-jurmarkt, .jurmarkt-logo-wrp {
	display: inline-block; }

.madeby-jurmarkt {
	padding-right: 20px;
	a {
		border-bottom: 1px solid $accent-color;
		text-decoration: none;
		color: $font-color-w;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: $font-color-w;
			border-bottom-color: transparent; } } }
