// Magnific Popup
.white-popup {
	position: relative;
	background: #FFF;
	padding: 20px 70px;
	width: auto;
	max-width: 500px;
	margin: 20px auto; }

#call-popup {
	h3 {
		text-align: center; }
	form {
		input {
			display: block;
			width: 100%;
			height: 45px;
			margin-bottom: 20px; }
		.btn-wrp {
			text-align: center; }
		.btn {
			font-size: 20px;
			margin: 0 auto;
			margin-top: 20px; }
		.confidential {
			font-size: 12px;
			text-align: center;
			margin-top: 20px; } } }
