/*==========  Desktop First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

	.top-menu {
		ul {
			li {
				margin-top: 90px; } } }
	.contacts_main-hdr {
		text-align: left; }
	.contacts_main-hdr--phone-sect h3, .contacts_main-hdr--phone-sect p {
		text-align: left; }
	.contacts_main-hdr--phone-sect h3::before {
		left: -26px; }
	.contacts_main-hdr--phone-sect h3 {
		margin-top: 34px;
		white-space: nowrap; }
	.contacts_main-hdr--email p {
		white-space: nowrap; }

	.confidential_contact-form {
		margin-bottom: 20px; }

	.item_s-services {
		width: 33%;
		margin-top: 20px;
		margin-bottom: 20px; }

	.img-wrp_slide {
		margin-left: 40px; }

	.confidential_contact-form_submit-s {
		text-align: center;
		right: 0;
		left: 0;
		bottom: -80px;
		margin: 0 auto; }

	.jurmarkt-logo-wrp, .madeby-jurmarkt {
		display: block; }
	.jurmarkt-logo-wrp {
		text-align: right;
		margin-right: 60px; }
	.copyright {
		padding-top: 0; }

	.name_team {
		h3 {
			white-space: normal; } }
	.item_s-services--about-s--adv {
		width: auto!important;
		margin-top: 60px; } }

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

	.logo-wrp_main-hdr {
		position: static;
		text-align: center; }
	.contacts_main-hdr {
		text-align: center;
		margin: 0 0;
		p {
			margin-right: 0; } }
	.contacts_main-hdr--phone-sect {
		h3 {
			display: inline-block;
			margin: 10px auto 0 0;
			padding: 0; } }
	.contacts_main-hdr {}


	.contacts_main-hdr--phone-sect p {
		text-align: center; }

	.contacts_main-hdr--email p {
		padding-left: 53px; }

	.top-menu {
		ul {
			display: none; } }

	.main-hdr {
		&::after {
			display: none; } }

	.img-wrp_s-main-info {
		img {
			height: auto;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0; } }
	.info_photo-wrp {
		top: 20px; }

	.item_s-services {
		width: 49%; }

	.info-item_about--list {
		margin-top: 105px; }

	.item_s-services {
		margin-top: 40px; }

	.content-wrp_slide {
		text-align: center; }

	.img-wrp_slide {
		margin-left: 0;
		display: block;
		margin: 15px auto; }

	.text-wrp_slide {
		padding: 0;
		display: inline-block;
		width: 80%;
		margin: 15px auto; }

	.content_slide p::before {
		left: -20px; }

	.content_slide p::after {
		right: -20px; }

	.jurmarkt-logo-wrp {
		text-align: left;
		margin-top: 15px; }

	.main-footer {
		padding-bottom: 20px; }

	.img-wrp_rec-s {
		margin: 10px 0; }

	.img-wrp--certificate img {
		max-width: 50%; }

	.services-aside {
		h2 {
			margin-top: 30px; } }

	.video-wrp_rec-s {
		margin-top: 30px; }

	.contacts_main-hdr--address {
		margin-top: 30px; } }
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

	.submit-form_submit-s input, .submit-form_submit-s button {
		display: block;
		width: 290px;
		margin: 15px auto; }

	.item_serv-detailed {
		margin-top: 25px; }

	.btn-wrp_blog {
		text-align: left; }



	/*Disable Animation on Mobile Devices*/
	/*CSS transitions*/
	-o-transition-property: none !important;
	-moz-transition-property: none !important;
	-ms-transition-property: none !important;
	-webkit-transition-property: none !important;
	transition-property: none !important;
	/*CSS transforms*/
	-o-transform: none !important;
	-moz-transform: none !important;
	-ms-transform: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	/*CSS animations*/
	-webkit-animation: none !important;
	-moz-animation: none !important;
	-o-animation: none !important;
	-ms-animation: none !important;
	animation: none !important; }

// Custom
@media only screen and (max-width : 780px) {
	/**/ }

// Custom
@media only screen and (max-width : 720px) {
	/**/ }

// Custom
@media only screen and (max-width : 620px) {

	.address-wrp--map {
		width: 270px;
		padding-left: 10px;
		h1 {
			display: none; }
		ul {
			position: relative;
			top: 50%;
			transform: translateY(-50%); } } }

/* CUSTOM */
@media only screen and (max-width : 490px) {

	/**/ }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

	.item_s-services {
		width: 99%; }

	.owl-theme .owl-controls {
		top: 35%; }

	.address-wrp--map::after {
		display: none; }

	.section_heading-wrp--inner-serv {
		h1 {
			font-size: 32px; } }

	.white-popup {
		padding: 20px 20px; } }

/* CUSTOM */
@media only screen and (max-width : 410px) {

	.adv-s_about-pg--list {
		padding-left: 0;
		&::before {
			display: none; } }
	.section-wa-heading-fix {
		h1 {
			font-size: 28px; } } }

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

	/**/ }

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }


// Custom
@media only screen and (max-width : 990px) {

	/**/ }









