.blog-s {
	background-color: #fff; }

.blog-article {
	margin-bottom: 30px; }

.article-info_blog {
	div {
		display: inline-block;
		padding: 0 5px;
		margin-bottom: 5px;
		font-size: 14px;
		position: relative;
		margin-top: 20px;
		&::after {
			content: '/';
			position: absolute;
			top: 0;
			right: 0;
			width: 2px;
			height: 2px; } } }

.img-wrp_blog {
	overflow: hidden;
	img {
		max-width: 100%;
		height: auto; } }

.submit-button_blog {
	font-size: 12px;
	padding: 8px 30px;
	width: auto;
	max-width: 230px; }


.btn-wrp_blog {
	text-align: right;
	margin-bottom: 20px; }

.heading_blog {
	h3 {
		margin-bottom: 0;
		margin-top: 5px; } }

// inner blog

.page-template {
	background-color: #fff;
	img {
		max-width: 50%;
		height: auto;
		float: left;
		margin: 20px 20px 20px 0; } }
