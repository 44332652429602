.recommendations-s {
	background-color: #fff; }

.services-aside {
	ul {
		padding-left: 0;
		list-style-type: none;
		li {
			margin-top: 10px;
			padding-left: 30px;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 30px;
				height: 10px;
				left: 0;
				top: 8px;
				background-image: url(../img/about_page/list-style-item.png);
				background-repeat: no-repeat; } } }
	h2 {
		margin-top: 0; }
	a {
		&:hover, &:active, &:focus {
			color: $font-color-b; } } }

.recommendation {
	margin-top: 30px; }

.img-wrp--certificate {
	overflow: hidden;
	img {
		max-width: 100%;
		height: auto; } }

.video-wrp_rec-s {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 35px;
	height: 0;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; } }

.name_rec-s {
	font-family: "OpenSans-Semibold";
	font-size: 	20px;
	margin-bottom: 15px; }

.city_rec-s, .phone_rec-s {
	display: inline-block;
	font-family: "OpenSans-Semibold";
	font-size: 16px; }

.phone_rec-s {
	margin-left: 20px; }



